import PropTypes from 'prop-types'
import React from 'react'
import Container from '../../lib/Container'
import classnames from 'classnames'
import PoweredByHorizontal from './PoweredByHorizontal'
import SessionStore from '../../auth/src/stores/SessionStore'
import StylishHatAndTails from '../StylishHatAndTails'
import * as Links from '../Links'

import {hasWhiteLabelCobranding, hasEverplansCobranding} from '../../lib/cobrandingTools'

import './footer.scss'

const ROUTES_WITH_HIDDEN_FOOTER = [
  '/auth/tfa-intro',
  '/auth/security'
]

export default class FooterController extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cobranding: this.brandingStore().getState().data.cobranding,
      currentUser: SessionStore.getState().currentUser
    }

    this.updateCobranding = this.updateCobranding.bind(this)
    this.updateCurrentUser = this.updateCurrentUser.bind(this)
  }
  componentDidMount() {
    this.brandingStore().addChangeListener(this.updateCobranding)
    SessionStore.addChangeListener(this.updateCurrentUser)
  }
  componentWillUnmount() {
    this.brandingStore().removeChangeListener(this.updateCobranding)
    SessionStore.removeChangeListener(this.updateCurrentUser)
  }
  brandingStore() {
    return Container.getStore('config')
  }
  updateCobranding() {
    var state = this.brandingStore().getState()
    if (state.data)
      this.setState({cobranding: state.data.cobranding})
  }
  updateCurrentUser() { this.setState({currentUser: SessionStore.getState().currentUser}) }

  isHidden() {
    return this.props.location && ROUTES_WITH_HIDDEN_FOOTER.includes(this.props.location.pathname) && hasEverplansCobranding(this.state.cobranding)
  }

  render () {
    if (this.isHidden())
      return null
    else
      return <Footer cobranding={this.state.cobranding} isFirmUser={this.state.currentUser ? this.state.currentUser.firm_user : false} />
  }
}

export class Footer extends React.Component {
  render() {
    const whiteLabelCobrandingEnabled = hasWhiteLabelCobranding(this.props.cobranding)

    return (
      <div className='footer-one hat-and-tails'>
        <StylishHatAndTails cobranding={this.props.cobranding} />
        <footer className={classnames({cobranded: whiteLabelCobrandingEnabled})} >
          {
            whiteLabelCobrandingEnabled &&
            (
              <div className='footer-upper'>
                <div className='cobranding-logo'>
                  <img src={this.props.cobranding.header_logo} />
                </div>
                <div className='cobranded-divider' />
              </div>
            )
          }
          <nav>
            {
              whiteLabelCobrandingEnabled &&
                <PoweredByHorizontal {...this.props.cobranding} />
            }
            <div className='menu-container'>
              <Menu {...this.props} />
            </div>
          </nav>
        </footer>
      </div>
    )
  }
}

FooterController.propTypes = {
  location: PropTypes.object
}

Footer.propTypes = {
  cobranding: PropTypes.object.isRequired
}

const Menu = props => {
  var year = new Date().getFullYear()
  return (
    <div className='menu'>
      <div className='menu-item'>
        {`© ${year} Everplans`}
      </div>
      <div className='menu-item'><Links.Terms target='_blank' /></div>
      <div className='menu-item'><Links.Privacy target='_blank' /></div>
      <div className='menu-item'><Links.Security target='_blank' /></div>
      <div className='menu-item'><Links.ContactUs /></div>
      <div className='menu-item'><Links.Help advisor={props.isFirmUser}><strong>Help</strong></Links.Help></div>
    </div>
  )
}
