import PropTypes from 'prop-types'
import {Component} from 'react'

import CobrandingActions from '../../shared_actions/CobrandingActions'
import Container from '../../lib/Container'
import storePrototype from '../../shared_components/StorePrototype'

Container.registerStore('cobrandings', storePrototype(CobrandingActions.Types.DID_UPDATE_COBRANDING))


const cobrandingWrapper = (ComponentToBeWrapped, cobrandingIdFinder, shouldIncludeCobrandable) => {
  class CobrandingController extends Component {
    constructor(props) {
      super(props)

      const cobrandingId = cobrandingIdFinder(props)
      this.store = Container.getStore('cobrandings')

      this.state = {
        cobranding: {},
        cobrandingId,
        loadingCobranding: !!cobrandingId
      }

      this.onUpdate = this.onUpdate.bind(this)
      this.setCobranding = this.setCobranding.bind(this)
    }

    componentDidMount() {
      this.store.addChangeListener(this.onUpdate)
      if (this.state.cobrandingId) { // Only fetch if we actually have an ID to use
        CobrandingActions.fetchCobranding({
          id: this.state.cobrandingId,
          onSuccess: this.setCobranding, // Until we set a store pattern for API v2 I'm just passing a callback to the action. --BLR
          params: shouldIncludeCobrandable ? '?include=cobrandable' : '' // Until we agree on a better pattern for API v2 for sending params -- ZD
        })
      }
    }

    onUpdate() { this.setCobranding(this.store.getState().data) }

    componentWillUnmount() { this.store.removeChangeListener(this.onUpdate) }

    setCobranding(apiResponse) {
      this.setState({
        cobranding: apiResponse.body.data,
        loadingCobranding: false
      })
    }

    render() {
      return (
        <ComponentToBeWrapped
          cobranding={this.state.cobranding}
          loadingCobranding={this.state.loadingCobranding}
          {...this.props}
        />
      )
    }
  }

  CobrandingController.displayName = `CobrandingWrapped${ComponentToBeWrapped.name}`

  CobrandingController.defaultProps = {
    firm: {
      data: {cobranding_id: null}
    }
  }

  CobrandingController.propTypes = {
    firm: PropTypes.shape({
      data: PropTypes.shape({
        cobranding_id: PropTypes.string
      })
    }),
    params: PropTypes.shape({
      cobrandingPreviewId: PropTypes.string
    })
  }


  return CobrandingController
}

export default cobrandingWrapper
