import apiV2 from '../lib/apiV2'
import AppDispatcher from '../lib/ep-dispatcher'
import {constructPayload} from '../lib/apiHelpers'
import TestPrep from '../lib/TestPrep'


const CobrandingActions = {
  ...TestPrep,
  Types: ['DID_UPDATE_COBRANDING', 'GOT_COBRANDING'].reduce((types, type) => ({...types, [type]: type}), {}),
  fetchCobranding({id, onSuccess, params}) {
    apiV2.GET({
      id,
      onSuccess,
      params,
      resource: 'cobrandings'
    })
  },
  updateCobranding({id, attributes}) {
    apiV2.PATCH({
      body: constructPayload({attributes, id, type: 'cobrandings'}),
      id,
      onSuccess: data => AppDispatcher.dispatch({
        actionType: CobrandingActions.Types.DID_UPDATE_COBRANDING,
        data
      }),
      resource: 'cobrandings'
    })
  }
}


export default CobrandingActions
