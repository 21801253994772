/** @module
 * This file contains tools that have jsx templating so vite is able to build without errors
 * We should look out for opportunites to convert this into a directory as this file grows
 */
import {createRoot} from 'react-dom/client'

import Logger from './NewLogger'
import {itemNameOrCount} from './clientActivityTools'
import {pluralize} from './tools'
import routerUtils from './routerUtils'

// Logging components and wrappers:
const LogClickWrapper = props => {
  const logThenClick = clickEvent => {
    if (clickEvent)
      clickEvent.preventDefault()

    Logger.log(props.event)
    props.onClick()
  }

  return routerUtils.childrenWithProps(props.children, {onClick: logThenClick})
}

export const createLogComponent = (Component, props) => {
  const {event, onClick, ...otherProps} = props

  return <LogClickWrapper event={event} onClick={onClick}><Component {...otherProps} /></LogClickWrapper>
}

export const addedDeputyText = ({activity, client}) => {
  if (activity.get('relationship') === 'Other' || !activity.get('relationship'))
    return 'added a new Deputy.'
  else
    return [`added ${client.get('pronoun')} `, <strong key={client.get('id')}>{activity.get('relationship').toLowerCase()}</strong>, ' as a Deputy.']
}

export const deputyActivityText = ({activity, client}) => {
  if (activity.get('requested-pro-everplan'))
    return ['Deputy, ', <strong key={client.get('id')}>{activity.get('name')}</strong>, ', has requested an Everplan from you.']
  else
    return addedDeputyText({activity, client})
}

export const deputyPermissionActivityText = ({client, activity}) => {
  const itemCount = activity.get('item-count')

  return [
    `shared${itemCount > 1 ? ' ' : ' the '}`,
    <strong key={client.get('id')}>{itemNameOrCount(activity)} {itemCount > 1 && pluralize('section', itemCount)}</strong>,
    itemCount > 1 ? ` of ${client.get('pronoun')} Everplan ` : ' section ',
    'with a Deputy.'
  ]
}

export const vaultActivityText = ({activity, client}) => {
  const hasMoreThanOneItem = activity.get('item-count') > 1

  return [
    `${activity.get('status') === 'response_modified' ? 'edited' : 'added info to'}${hasMoreThanOneItem ? ' ' : ` ${client.get('pronoun')} `}`,
    <strong key={client.get('id')}>{itemNameOrCount(activity)} {hasMoreThanOneItem && pluralize('section', activity.get('item-count'))}</strong>,
    hasMoreThanOneItem ? ` of ${client.get('pronoun')} Everplan.` : 'section.'
  ]
}

export const attachComponentToDOM = ({Component, rootIdentifier, className, flavor, otherProps}) => {
  let props = otherProps

  if (className)
    props = {...props, className}

  if (flavor)
    props = {...props, flavor}

  if ($(`#${rootIdentifier}`).length > 0) {
    const root = createRoot(document.getElementById(rootIdentifier))
    root.render(<Component {...props} />)
  }
}
