import Dev from '../../../dev_only/tools'
import extra_storage from '../../../lib/extra_storage'
import jsonStatham from '../../../lib/jsonStatham'
import {railsUrl, webAppsUrl, normalizeNextPath, corpMaxWrapperUrl, corpMaxDashboardUrl} from '../../../lib/urlTools'
import routerUtils from '../../../lib/routerUtils'
import SessionApi from '../web/SessionApi'
import SessionStore from '../stores/SessionStore'

const sessionUtils = {
  // actually used in external app by the rails app
  redeemToken() {
    const token = decodeURIComponent(window.location.pathname.split('/')[2]) // token is immediately after the last slash
    let additionalHeaders = {}

    if (token)
      additionalHeaders = {Authorization: `Token token=${token}`}

    const nextPath = normalizeNextPath(decodeURIComponent(window.location.search.split('=')[1])) // the url is the first query param
    const request = jsonStatham.buildRequest('/sessions/redeem', 'POST', null, {additionalHeaders})

    jsonStatham.sendRequest(request)
      .then(data => { extra_storage.setItem('authToken', data.session.session_token) })
      .always(() => {
        $('.loading').append(`<br/><br/><br/>If your page doesn't load click <a href='${nextPath}'>here</a>.`)
        routerUtils.setLocation(nextPath)
      })
  },

  transferSession(host, fullPath) {
    jsonStatham.post('/sessions/transfer', null).then(data => {
      routerUtils.setLocation(`${host}/redeem_transfer/${encodeURIComponent(data.session.transfer)}?=${encodeURIComponent(fullPath)}`)
    })
  },

  destroySession(messageId, nextPath) {
    extra_storage.removeItem('authToken')
    let queryString = ''

    if (messageId)
      queryString += `?msg_id=${messageId}`
    if (nextPath) {
      queryString += queryString.length > 1 ? '&' : '?'
      queryString += `nextPath=${nextPath}`
    }

    routerUtils.setLocation(`${WEBAPPS_HOST}/#/auth/sign_in${queryString}`)
  },

  forwardSession(route) {
    const session = SessionStore.getState()
    const nextRoute = route ? route : session.nextRoute

    if (nextRoute)
      sessionUtils.transferSession(API_HOST, webAppsUrl(nextRoute))
    else if (session.nextPath)
      sessionUtils.transferSession(API_HOST, railsUrl(session.nextPath))
    else
      sessionUtils.transferSession(API_HOST, API_HOST)
  },

  hasSession() { return !!extra_storage.getItem('authToken') },

  wipeSession() {
    // This is only used if you show up on the signon page and you happen to have a token.
    // It's dangerous because there's an api call, and if you don't know what you are doing
    // you'll get stuck in somekind of invalid state. Just don't use it, okay? --BJK
    Dev.log("[WARNING] Unless you're nuc'ing a session from sign_in, you REALLY shouldn't be using wipeSession.")
    SessionApi.signOutUser()
  },

  authenticateAndStartSession(token, nextPath = '/pending-requests') {
    SessionApi._setToken(token) // Puts the token in local storage for others to use
    SessionApi._startSession(true) // Starts the keepalive ping, so the user doesn't get logged out.
    routerUtils.replace(nextPath)
  },

  jiclAuthenticate(token, nextPath) {
    const newPath = nextPath || '/saved-jicl/preview'

    sessionUtils.authenticateAndStartSession(token, newPath)
  },

  cleanupJiclSession() {
    SessionApi._clearSession()
    routerUtils.replace('/jicl/start')
  },

  sendUserToNextRoute(user, partner = {}) {
    if (user.admin)
      sessionUtils.forwardSession()
    else if (user.account_manager)
      routerUtils.push('pro/firm-admin/all-firms')
    else if (user.firm_user)
      routerUtils.push('pro')
    else if (user.freemium && partner['suppress-freemium'])
      routerUtils.setLocation(corpMaxWrapperUrl('account-settings/payment-form'))
    else
      routerUtils.setLocation(corpMaxDashboardUrl)
  }
}

export default sessionUtils
